import React from 'react';
import { Spin, Typography } from 'antd';
import indicatorIcon from './CustomSpinnerIcon';

const LoaderComponent = ({ msg = "Loading..." }) => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: "column",
            height: '100vh',
            width: '100vw',
            background: 'rgba(0, 0, 0, 0.1)'
        }}>
            <Spin size="large" indicator={indicatorIcon} />
            <Typography.Title level={5} style={{ marginTop: '16px' }}>
                {msg}
            </Typography.Title>
        </div>
    );
};

export default LoaderComponent;
