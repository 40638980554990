import { apiBaseUrl, storageBaseUrl } from "../config";
import axios from "axios";

export function getAvatarUrl(avatarFileName) {
    return storageBaseUrl + "avatars/" + avatarFileName + ".webp"
}
export function getAuthHeader() {
    return { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } }
}
export function hasRole(user, roleName) {
    if (user && user.roles.length > 0) {
        for (let i = 0; i < user.roles.length; i++) {
            if (user.roles[i].name === roleName) {
                return true
            }
        }
    }
    return false
}
export function getFullName(user) {
    return user.f_name + (user.l_name ? " " + user.l_name : "")
}
export function getApiPrefix(role) {
    if (!['super-admin', 'client', 'team'].includes(role)) {
        return ""
    }
    return (role === 'client' ? "company/" : (role === 'team' ? "team/" : ""))
}
export function getRole(user) {
    if (user && user.roles.length > 0) {
        return user.roles[0].display_name
    }
    return ''
}
export function getRoleName(user) {
    if (user && user.roles.length > 0) {
        return user.roles[0].name
    }
    return ''
}

export function login(email, password) {
    return axios.post(apiBaseUrl + 'login', { email, password })
}
export function register(dataToPost) {
    return axios.post(apiBaseUrl + 'register', dataToPost)
}
export function forgotPassword(dataToPost) {
    return axios.post(apiBaseUrl + 'forgot-password', dataToPost)
}
export function resetPassword(dataToPost) {
    return axios.post(apiBaseUrl + 'reset-password', dataToPost)
}
export function getProfile(token) {
    return axios.get(apiBaseUrl + 'user', {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
}
export function updateProfile(values) {
    return axios.put(apiBaseUrl + `user`, values, getAuthHeader())
}
export function uploadAvatar(formData) {
    return axios.post(apiBaseUrl + `avatar`, formData, getAuthHeader())
}
export function changePassword(values) {
    return axios.post(apiBaseUrl + `password`, values, getAuthHeader())
}
export function logout(token) {
    return axios.post(apiBaseUrl + 'logout', undefined, {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
}
