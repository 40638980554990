import React, { Suspense, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile } from '../services/auth.service';
import { setUserData, setToken, setUserDataLoading } from '../stores/auth.slice';
import LoaderComponent from './LoaderComponent';

// Lazy load the route components
const PrivateRoutes = React.lazy(() => import('../Routes/PrivateRoutes'));
const PublicRoutes = React.lazy(() => import('../Routes/PublicRoutes'));

const AuthGuard = () => {
    const token = useSelector((state) => state.auth.token);
    const user = useSelector((state) => state.auth.user);
    const userDataLoading = useSelector((state) => state.auth.userDataLoading);
    const dispatch = useDispatch();

    useEffect(() => {
        if (user === null && token && !userDataLoading) {
            dispatch(setUserDataLoading(true));
            getProfile(token).then((res) => {
                dispatch(setUserData(res.data));
            }).catch((err) => {
                if (err.response?.status === 401) {
                    window.localStorage.removeItem('token');
                    dispatch(setUserData(null));
                    dispatch(setToken(null));
                } else {
                    console.error("Get User Details Error", err);
                }
            }).finally(() => {
                dispatch(setUserDataLoading(false));
            });
        }
    }, [token, user]);

    if (userDataLoading) {
        return (
            <LoaderComponent msg="Verifing Session..." />
        );
    }

    return (
        <Suspense fallback={<LoaderComponent msg="Loading Assets..." />}>
            {!token ? <PublicRoutes /> : <PrivateRoutes />}
        </Suspense>
    );
};

export default AuthGuard;
